import React, { type ChangeEvent, useState, useMemo, useEffect, useCallback } from 'react'
import {
  Box,
  Typography,
  Modal,
  MenuItem,
  Stack,
  CircularProgress
} from '@mui/material'
import DatePicker from 'react-datepicker'
import { isAfter, isBefore } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import './UpdateModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import { CustomSelectInputField, CustomInputField } from 'src/components/TextField'
import theme from 'src/themes/theme'
import AxiosClient from 'src/clients/axios.client'
import { EventModel, EventWeddingModel } from 'src/data/models'

interface Props {
  showModal: boolean
  eventId: string
  setShowModal: (val: boolean) => void
  submit: (data: any) => void
}

const EVENT_TYPES = [
  { value: 'wedding', label: 'Casamento' },
  { value: 'birthday_party', label: 'Aniversário' },
  { value: 'christening_blessing', label: 'Batizado' },
  { value: 'concert', label: 'Show' },
  { value: 'conference', label: 'Conferência' },
  { value: 'seminar', label: 'Seminário' },
  { value: 'exhibition', label: 'Exibição' },
  { value: 'fair', label: 'Feira' },
  { value: 'festival', label: 'Festival' },
  { value: 'workshop', label: 'Workshop' },
  { value: 'product_launch', label: 'Lançamento' },
  { value: 'award_ceremony', label: 'Cerimônia de Premiação' },
  { value: 'themed_party', label: 'Festa Temática' },
  { value: 'charity_auction', label: 'Leilão' },
  { value: 'theater', label: 'Teatro' }
]

const EVENT_ACCESSIBILITY = [
  { value: 'true', label: 'Restrito a convidados' },
  { value: 'false', label: 'Para todo o público' },
]

const UpdateEventModal: React.FC<Props> = ({ showModal, setShowModal, submit, eventId }) => {
  const [endDateError, setEndDateError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const [registrationDeadlineDateError, setRegistrationDeadlineDateError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState<EventModel & { weddingDetails?: Partial<EventWeddingModel> }>({
    agenda: [""],
    budget: 0,
    userId: "",
    cancellationReason: "",
    createdAt: "",
    description: "",
    internalNotes: "",
    dressCode: "",
    endAt: new Date(),
    startAt: new Date(),
    eventType: "",
    id: "",
    isCancelled: false,
    isRSVPRequired: true,
    isTicketed: false,
    location: "",
    registrationDeadline: new Date(),
    speakers: [""],
    sponsors: [""],
    theme: "",
    maxTicketQuantity: 0,
    title: "",
    updatedAt: "",
    maxAgeForFreeAdmission: 0,
    isPrivate: false,
    ticketsCreated: 0,
    weddingDetails: undefined,
  })

  const isDisabled = useMemo(() => {
    const disable = (
      event.title === '' || event.location === '' || startDateError || endDateError || registrationDeadlineDateError
    )
    return disable
  }, [event, startDateError, endDateError, registrationDeadlineDateError])

  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    submit(event)
  }

  const handleStartDateChange = (date: Date): void => {
    handleEvent(date, 'startAt')
    if (date && isBefore(date, new Date())) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    if (date && isAfter(date, event.endAt)) {
      setEndDateError(true)
    } else setEndDateError(false)

    if (date && event.registrationDeadline && isBefore(date, event.registrationDeadline)) {
      setRegistrationDeadlineDateError(true)
    } else setRegistrationDeadlineDateError(false)
  }

  const handleEndDateChange = (date: Date): void => {
    handleEvent(date, 'endAt')
    if (date && (isBefore(date, new Date()) || isBefore(date, event.startAt))) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  }

  const handleRegistrationDeadlineDateChange = (date: Date): void => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(23, 59, 59, 999);
    handleEvent(adjustedDate, 'registrationDeadline')
    if (event.startAt && adjustedDate && (isBefore(event.startAt, adjustedDate) || isBefore(adjustedDate, new Date()))) {
      setRegistrationDeadlineDateError(true);
    } else {
      setRegistrationDeadlineDateError(false);
    }
  }

  const handleEvent = (val: string | boolean | Date, attr: string): void => {
    setEvent((prevState) => ({
      ...prevState,
      [attr]: val
    }))
  }

  const endErrorClass = endDateError ? 'error' : '';
  const startErrorClass = startDateError ? 'error' : '';
  const deadLineErrorClass = registrationDeadlineDateError ? 'error' : '';

  const getEvent = useCallback(async () => {
    await AxiosClient.getEventById({ eventId, setLoading, setEvent })
  }, [eventId])

  useEffect(() => {
    getEvent()
  }, [getEvent])

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Atualizar Evento</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          {loading ?
            <Box sx={styles.loaderContainer}>
              <CircularProgress color="secondary" sx={styles.loading} />
            </Box> :
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' }
              }}
            >
              <Box sx={styles.row}>
                <CustomInputField
                  required
                  placeholder="Ex: Casamento João e Maria"
                  label="Nome do Evento:"
                  value={event.title}
                  maxLength={60}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value, 'title')
                  }}
                />
              </Box>
              <Box sx={styles.row}>
                <Box sx={styles.pickerScheduleRow}>
                  <Typography
                    id="modal-modal-description"
                    sx={styles.dateLabel}
                  >
                    De:
                  </Typography>
                  <DatePicker
                    className={`custom-datepicker ${startErrorClass}`}
                    selected={event.startAt}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d MMMM, yyyy h:mm aa"
                    timeCaption="Hora"
                    locale={ptBR}
                  />
                </Box>
                <Box sx={styles.pickerScheduleRow}>
                  <Typography
                    id="modal-modal-description"
                    sx={styles.dateLabel}
                  >
                    Até:
                  </Typography>
                  <DatePicker
                    className={`custom-datepicker ${endErrorClass}`}
                    selected={event.endAt}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d MMMM, yyyy h:mm aa"
                    timeCaption="Hora"
                    locale={ptBR}
                  />
                </Box>
              </Box>

              <Box sx={styles.row}>
                <CustomSelectInputField
                  disabled
                  label="Tipo de Evento"
                  value={event.eventType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value, 'eventType')
                  }}
                >
                  {EVENT_TYPES.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelectInputField>

                <CustomSelectInputField
                  label="Exigir Confirmação de Presença"
                  value={event.isRSVPRequired || false}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value === 'true', 'isRSVPRequired')
                  }}
                >
                  <MenuItem key='true' value='true'>
                    Sim
                  </MenuItem>
                  <MenuItem key='false' value='false'>
                    Não
                  </MenuItem>
                </CustomSelectInputField>
                {event.isRSVPRequired &&
                  <Box sx={styles.pickerScheduleRow}>
                    <Typography
                      id="modal-modal-description"
                      sx={styles.dateLabel}
                    >
                      Confirmar presença até
                    </Typography>
                    <DatePicker
                      className={`custom-datepicker ${deadLineErrorClass}`}
                      selected={event.registrationDeadline}
                      onChange={handleRegistrationDeadlineDateChange}
                      dateFormat="d MMMM, yyyy"
                      locale={ptBR}
                    />
                  </Box>
                }

              </Box>

              <Box sx={styles.row}>
                <CustomSelectInputField
                  label="Acessibilidade"
                  value={event.isPrivate}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value === 'true', 'isPrivate')
                  }}
                >
                  {EVENT_ACCESSIBILITY.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelectInputField>
                <CustomInputField
                  label="Idade Franca Até:"
                  type="number"
                  value={event.maxAgeForFreeAdmission}
                  onChange={(event) => handleEvent(event.target.value, 'maxAgeForFreeAdmission')}
                />
                <CustomSelectInputField
                  label="Ingresso Obrigatório?"
                  value={event.isTicketed}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value === 'true', 'isTicketed')
                  }}
                >
                  <MenuItem key='true' value='true'>
                    Sim
                  </MenuItem>
                  <MenuItem key='false' value='false'>
                    Não
                  </MenuItem>
                </CustomSelectInputField>
                {event.isTicketed &&
                  <>
                    <CustomInputField
                      label="Nº Ingressos:"
                      placeholder="Nº Ingressos"
                      type="number"
                      value={event.maxTicketQuantity}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>
                      ) => {
                        handleEvent(
                          event.target.value,
                          'maxTicketQuantity'
                        )
                      }}
                    />
                  </>
                }
              </Box>
              {/* {event.eventType === "wedding" ? (
                <Box sx={styles.row}>
                  <CustomInputField
                    required
                    label="Nome da Noiva:"
                    placeholder=""
                    value={event?.weddingDetails?.brideName || ''}
                    maxLength={60}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleWeddingDetailsChange(event.target.value, 'brideName');
                    }}
                  />
                  <CustomInputField
                    required
                    label="Nome do Noivo:"
                    placeholder=""
                    value={event.weddingDetails?.groomName || ''}
                    maxLength={60}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleWeddingDetailsChange(event.target.value, 'groomName');
                    }}
                  />
                </Box>
              ) : null} */}
              <Box sx={styles.row}>
                <CustomInputField
                  required
                  label="Local:"
                  placeholder="Ex: Hotel Fazenda"
                  value={event.location}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value, 'location')
                  }}
                />
              </Box>
              <Box sx={styles.row}>
                <CustomInputField
                  label="Descrição"
                  placeholder="Adicione uma descrição do evento, slogan ou qualquer outra informação útil"
                  value={event.description || ''}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value, 'description')
                  }}
                  maxLength={300}
                />
              </Box>
              {/* <Box sx={styles.row}>
                <CustomInputField
                  label="Nota Importante"
                  placeholder="Adicione notas importantes, termos e condições, transferência de ingressos e política de reembolso"
                  value={event.internalNotes || ''}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleEvent(event.target.value, 'internalNotes')
                  }}
                  maxLength={300}
                />
              </Box> */}
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                sx={{ paddingTop: 5 }}
              >
                <Button onClick={handleClose} bgColor="transparent" variant='outlined'>Fechar</Button>
                <Button onClick={handleSubmit} isDisable={isDisabled}>Salvar</Button>
              </Stack>
            </Box>
          }

        </Box>
      </Box>
    </Modal>
  )
}

export default UpdateEventModal
