import React, { type ChangeEvent, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Modal,
  MenuItem,
  Stack,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import "./CreateEventModal.css";
import Button from "src/components/Button";
import Header from "src/components/Header";
import styles from "./styles";
import { CustomInputField, CustomSelectInputField } from "src/components/TextField";
import { isAfter, isBefore } from "date-fns";
import theme from "src/themes/theme";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  submit: (data: any) => void;
}

const EVENT_TYPES = [
  { value: "wedding", label: "Casamento" },
  { value: "birthday_party", label: "Aniversário" },
  { value: "christening_blessing", label: "Batizado" },
  { value: "concert", label: "Show" },
  { value: "conference", label: "Conferência" },
  { value: "seminar", label: "Seminário" },
  { value: "exhibition", label: "Exibição" },
  { value: "fair", label: "Feira" },
  { value: "festival", label: "Festival" },
  { value: "workshop", label: "Workshop" },
  { value: "product_launch", label: "Lançamento" },
  { value: "award_ceremony", label: "Cerimônia de Premiação" },
  { value: "themed_party", label: "Festa Temática" },
  { value: "charity_auction", label: "Leilão" },
  { value: "theater", label: "Teatro" },
];

const EVENT_ACCESSIBILITY = [
  { value: "true", label: "Restrito a convidados" },
  { value: "false", label: "Para todo o público" },
];

const CreateEventModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  submit,
}) => {
  const initialDate = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() + 7);
    return new Date(dataAtual.toISOString());
  };

  const finalDate = () => {
    var dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() + 7);
    dataAtual.setHours(dataAtual.getHours() + 1);
    return new Date(dataAtual.toISOString());
  };

  const confirmPresenceDate = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() + 5);
    return new Date(dataAtual.toISOString());
  };

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(initialDate());
  const [endDate, setEndDate] = useState(finalDate());
  const [registrationDeadline, setRegistrationDeadline] = useState(
    confirmPresenceDate()
  );
  const [endDateError, setEndDateError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [registrationDeadlineDateError, setRegistrationDeadlineDateError] =
    useState(false);
  const [maxTicketQuantity, setMaxTicketQuantity] = useState(0);
  const [maxAge, setMaxAge] = useState(5);
  const [venue, setVenue] = useState("");
  const [bride, setBride] = useState<string | undefined>(undefined);
  const [groom, setGroom] = useState<string | undefined>(undefined);
  const [description] = useState("");
  const [note] = useState("");
  const [eventType, setEventType] = useState<string>("wedding");
  const [isRSVPRequired, setIsRSVPRequired] = useState<boolean>(true);
  const [isTicketed, setIsTicketed] = useState<boolean>(true);
  const [accessibility, setAccessibility] = useState<boolean>(true);
  const isDisabled = useMemo(() => {
    const disable =
      title === "" ||
      venue === "" ||
      startDateError ||
      endDateError ||
      registrationDeadlineDateError ||
      eventType === "" ||
      (eventType === "wedding" && (!bride || !groom));
    return disable;
  }, [
    title,
    venue,
    startDateError,
    endDateError,
    registrationDeadlineDateError,
    eventType,
    bride,
    groom,
  ]);

  const handleClose = (): void => {
    setShowModal(false);
  };
  const handleSubmit = (): void => {
    const data = {
      title,
      description,
      note,
      startAt: startDate,
      endAt: endDate,
      location: venue,
      eventType,
      isRSVPRequired,
      maxTicketQuantity,
      isTicketed,
      registrationDeadline,
      isPrivate: accessibility,
      maxAgeForFreeAdmission: maxAge,
      ...(eventType === "wedding" ? { groomName: groom } : {}),
      ...(eventType === "wedding" ? { brideName: bride } : {}),
    };

    submit(data);
  };

  const handleStartDateChange = (date: Date): void => {
    setStartDate(date);
    if (date && isBefore(date, new Date())) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    if (date && isAfter(date, endDate)) {
      setEndDateError(true);
    } else setEndDateError(false);

    if (date && isBefore(date, registrationDeadline)) {
      setRegistrationDeadlineDateError(true);
    } else setRegistrationDeadlineDateError(false);
  };

  const handleEndDateChange = (date: Date): void => {
    setEndDate(date);
    if (date && (isBefore(date, new Date()) || isBefore(date, startDate))) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const handleRegistrationDeadlineDateChange = (date: Date): void => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(23, 59, 59, 999);
    setRegistrationDeadline(adjustedDate);
    if (
      startDate &&
      adjustedDate &&
      (isBefore(startDate, adjustedDate) || isBefore(adjustedDate, new Date()))
    ) {
      setRegistrationDeadlineDateError(true);
    } else {
      setRegistrationDeadlineDateError(false);
    }
  };

  const handleTicketsAmount = (val: string): void => {
    setMaxTicketQuantity(+val);
  };
  const handleMaxAge = (val: string): void => {
    setMaxAge(+val);
  };
  const handleVenue = (val: string): void => {
    setVenue(val);
  };
  const handleGroom = (val: string): void => {
    setGroom(val);
  };
  const handleBride = (val: string): void => {
    setBride(val);
  };

  const handleTitle = (val: string): void => {
    setTitle(val);
  };
  const handleEventType = (val: string): void => {
    setEventType(val);
  };
  const handleIsTicked = (val: boolean): void => {
    setIsTicketed(val);
  };
  const handleIsRSVPRequired = (val: boolean): void => {
    setIsRSVPRequired(val);
  };
  const handleAccessibility = (val: boolean): void => {
    setAccessibility(val);
  };

  const endErrorClass = endDateError ? "error" : "";
  const startErrorClass = startDateError ? "error" : "";
  const deadLineErrorClass = registrationDeadlineDateError ? "error" : "";

  return (
    <Modal open={showModal}>
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Criar Evento</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
          >
            <Box sx={styles.row}>
              <CustomInputField
                required
                placeholder="Ex: Casamento João e Maria"
                label="Nome do Evento:"
                value={title}
                maxLength={60}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTitle(event.target.value);
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <Box sx={styles.pickerRow}>
                <Typography id="modal-modal-description" sx={styles.dateLabel}>
                  De:
                </Typography>
                <DatePicker
                  className={`custom-datepicker ${startErrorClass}`}
                  selected={startDate}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="d MMMM, yyyy h:mm aa"
                  timeCaption="Hora"
                  locale={ptBR}
                />
              </Box>
              <Box sx={styles.pickerRow}>
                <Typography id="modal-modal-description" sx={styles.dateLabel}>
                  Até:
                </Typography>
                <DatePicker
                  className={`custom-datepicker ${endErrorClass}`}
                  selected={endDate}
                  onChange={handleEndDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="d MMMM, yyyy h:mm aa"
                  timeCaption="Hora"
                  locale={ptBR}
                />
              </Box>
            </Box>

            <Box sx={styles.row}>
              <CustomSelectInputField
                required
                disabled
                label="Tipo de Evento"
                value={eventType}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleEventType(event.target.value);
                }}
              >
                {EVENT_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomSelectInputField>

              <CustomSelectInputField
                label="Exigir Confirmação de Presença:"
                value={isRSVPRequired}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleIsRSVPRequired(event.target.value === "true");
                }}
              >
                <MenuItem key="true" value="true">
                  Sim
                </MenuItem>
                <MenuItem key="false" value="false">
                  Não
                </MenuItem>
              </CustomSelectInputField>
              {isRSVPRequired && (
                <Box sx={[styles.pickerRow, {marginRight: '8px'}]}>
                  <Typography
                    id="modal-modal-description"
                    sx={styles.dateLabel}
                  >
                    Confirmar presença até:
                  </Typography>
                  <DatePicker
                    className={`custom-datepicker ${deadLineErrorClass}`}
                    selected={registrationDeadline}
                    onChange={handleRegistrationDeadlineDateChange}
                    dateFormat="d MMMM, yyyy"
                    locale={ptBR}
                  />
                </Box>
              )}
            </Box>

            <Box sx={styles.row}>
              <CustomSelectInputField
                label="Acessibilidade"
                value={accessibility}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleAccessibility(event.target.value === "true");
                }}
              >
                {EVENT_ACCESSIBILITY.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomSelectInputField>
              <CustomInputField
                // sx={[styles.ticket, { marginLeft: "5px", width: "500px" }]}
                label="Idade Franca Até:"
                type="number"
                value={maxAge}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleMaxAge(event.target.value);
                }}
              />
              <CustomSelectInputField
                label="Ingresso Obrigatório?"
                value={isTicketed}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleIsTicked(event.target.value === "true");
                }}
              >
                <MenuItem key="true" value="true">
                  Sim
                </MenuItem>
                <MenuItem key="false" value="false">
                  Não
                </MenuItem>
              </CustomSelectInputField>

              {isTicketed && (
                <>
                  <CustomInputField
                    // sx={[styles.ticket, { marginLeft: "5px" }]}
                    label="Nº Ingressos:"
                    placeholder="Nº Ingressos"
                    type="number"
                    value={maxTicketQuantity}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleTicketsAmount(event.target.value);
                    }}
                  />
                </>
              )}
            </Box>
            {eventType === "wedding" ? (
              <Box sx={styles.row}>
                <CustomInputField
                  required
                  label="Nome da Noiva:"
                  placeholder="Digite aqui.."
                  value={bride}
                  maxLength={60}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleBride(event.target.value);
                  }}
                />
                <CustomInputField
                  required
                  label="Nome do Noivo:"
                  placeholder="Digite aqui.."
                  value={groom}
                  maxLength={60}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleGroom(event.target.value);
                  }}
                />
              </Box>
            ) : null}
            <Box sx={styles.row}>
              <CustomInputField
                required
                label="Local:"
                placeholder="Ex: Hotel Fazenda"
                value={venue}
                maxLength={60}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleVenue(event.target.value);
                }}
              />
            </Box>
            {/* <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="description"
                label="Descrição"
                placeholder="Adicione uma descrição do evento, slogan ou qualquer outra informação útil"
                variant="standard"
                value={description}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>
                ) => {
                  handleDescription(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 300
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="note"
                label="Nota Importante"
                placeholder="Adicione notas importantes, termos e condições, transferência de ingressos e política de reembolso"
                variant="standard"
                value={note}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>
                ) => {
                  handleNote(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 300
                }}
              />
            </Box> */}
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 2 }}
          >
            <Button onClick={handleClose} variant="outlined">
              Fechar
            </Button>
            <Button onClick={handleSubmit} isDisable={isDisabled}>
              Criar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateEventModal;
